<template>
	<div>
		<p class="title">{{ title }}:</p>
		<p class="value">{{ value }}</p>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "Number Box",
		},
		value: {
			type: String,
			default: "$100.50",
		},
	},
};
</script>

<style lang="sass" scoped>
.title
   font-size: var(--d-lg)

.value
   font-size: var(--d-3xl)
   text-align: right
</style>