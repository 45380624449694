<template>
	<Layout class="container">
		<div class="section">
			<h1>Components</h1>
			<p>A page to display and prototype components</p>
		</div>
		<div>
			<AddLocation />
			<br />
			<AddAccount />
			<br />
			<GeneralTable
				:data="getLocations"
				title="Locations"
				fbRef="portfolio/locations"
			/>
			<br />
			<GeneralTable
				:data="getAccounts"
				title="Accounts"
				fbRef="portfolio/accounts"
			/>
			<br />
			<GeneralTable
				:data="getHoldings"
				title="Holdings"
				fbRef="portfolio/holdings"
			/>

			<!-- <add-holding :coinsList="getCoinsList" /> -->
			<!-- <h2>Prototypes</h2>
			<test-cors />
			<br />
			<br />
			<div>
				<h4 class="is-title is-3">total: {{ getTotalAssets }}</h4>
				<h4 class="is-title is-3">7 days: {{ getTotalAssets }}</h4>
				<h4 class="is-title is-3">30 days: {{ getTotalAssets }}</h4>
				<h4 class="is-title is-3">This season (s2): {{ getTotalAssets }}</h4>
			</div> -->
			<!-- <holdings-table
				:holdingsData="Object.values(holdings)"
				:tokenData="getTokenData"
			/> -->
		</div>
	</Layout>
</template>

<script>
import HoldingsTable from "@/components/HoldingsTable.vue";
import AddHolding from "@/components/AddHolding.vue";
import AddLocation from "@/components/AddLocation.vue";
import AddAccount from "@/components/AddAccount.vue";
import GeneralTable from "@/components/GeneralTable.vue";
import Layout from "../layouts/Wide.vue";
import { mapGetters } from "vuex";
import { map } from "@amcharts/amcharts4/.internal/core/utils/Array";

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters("market", ["getTokenData", "getCoinsList"]),
		...mapGetters("portfolio", [
			"getTotalAssets",
			"getHoldings",
			"getLocations",
			"getAccounts",
		]),
	},
	components: {
		/*eslint-disable*/
		HoldingsTable,
		AddHolding,
		Layout,
		AddLocation,
		AddAccount,
		GeneralTable,
	},
	// beforeMount() {
	// 	this.$store.dispatch("portfolio/fetchPortfolio");
	// },
};
</script>

<style lang="scss" scoped>
</style>