<template>
	<div class="add-holding" @keyup.enter="submit">
		<h2>Add Holding</h2>
		<b-field label="Hidden"
			><b-switch v-model="formData.isHidden"></b-switch
		></b-field>
		<b-field label="Collateral"
			><b-switch v-model="formData.isCollateral"></b-switch
		></b-field>
		<b-field label="Type">
			<b-select v-model="formData.Type">
				<option
					v-for="(option, i) in holdingTypes"
					:value="option.value"
					:disabled="option.disabled"
					:key="i"
					:class="{ disabled: option.disabled }"
				>
					{{ option.name }}
				</option>
			</b-select>
		</b-field>
		<b-field label="Chain">
			<b-input v-model="formData.Chain"></b-input>
		</b-field>
		<b-field label="Alternate Unit">
			<b-input v-model="formData.AlternateUnit"></b-input>
		</b-field>
		<b-field label="Quantity">
			<b-input v-model="formData.Quantity"></b-input>
		</b-field>

		<b-field label="Name" expanded>
			<b-autocomplete
				v-model="formData.DisplayName"
				:data="filteredDataArray"
				field="name"
				@select="(option) => (selected = option)"
			>
			</b-autocomplete>
		</b-field>
		<b-field label="Ticker">
			<b-input v-model="formData.Ticker"></b-input>
		</b-field>
		<b-field label="Portfolio">
			<b-select v-model="formData.Portfolio" placeholder="Select a name">
				<option v-for="option in portfolios" :value="option" :key="option">
					{{ option }}
				</option>
			</b-select>
		</b-field>

		<b-button @click="submit" class="is-primary" icon-left="plus"
			>Add Holding</b-button
		>
	</div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

import { mapActions } from "vuex";

export default {
	data() {
		return {
			//probably want this in the store
			selected: null,
			nn: "",
			portfolios: ["Delta", "Theta", "Gamma"],
			holdingTypes: [
				{
					name: "Token",
					disabled: false,
					value: "tokens",
				},
				{
					name: "NFT",
					disabled: true,
					value: "nfts",
				},
				{
					name: "Staked",
					disabled: true,
					value: "stakes",
				},
				{
					name: "Bot",
					disabled: true,
					value: "bots",
				},
				{
					name: "Physical",
					disabled: true,
					value: "physicals",
				},
				{
					name: "Fiat",
					disabled: true,
					value: "fiats",
				},
			],
			formData: {
				Type: "tokens",
				Quantity: "",
				DisplayName: "",
				Ticker: "",
				Chain: "",
				AlternateUnit: "",
				isHidden: "",
				isCollateral: "",
				Portfolio: "",
			},
		};
	},
	computed: {
		filteredDataArray() {
			return this.coinsList.filter((option) => {
				return (
					option.name
						.toString()
						.toLowerCase()
						.indexOf(this.nn.toLowerCase()) >= 0
				);
			});
		},
	},
	props: {
		coinsList: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		...mapActions("portfolio", ["addHolding"]),

		submit() {
			this.addHolding(this.formData).then(() => {
				// clear form
				this.formData = {
					Type: this.formData.Type,
					Quantity: "",
					DisplayName: "",
					Ticker: "",
					Chain: "",
					AlternateUnit: "",
					isHidden: "",
					isCollateral: "",
				};
			});
		},
	},
};
</script>

<style lang="sass" scoped>
.add-holding
   background-color: var(--color-b-alt)
   padding: var(--space-xs)

   ::v-deep
      label
         font-weight: 400
         color: var(--color-t-c)
      .disabled
         color: var(--color-b-alt-2)
      .dropdown-item *
         color: var(--color-b)
</style>