function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p',[_vm._v("This fee structure will be implemented on *.")]),_vm._v(" "),_c('p',[_vm._v("Any funds invested prior to * will be held in a separate account. This account will be allowed 1 withdrawal. Fees will be applied to the remaining capital.")]),_vm._v(" "),_c('p',[_vm._v("Fees will not be applied to funds invested prior to this date. Any funds invested before this date will be held in a separate account. This account will be")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"title":"Fee Structure"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }