<template>
	<div class="basic-layout">
		<img src="@/assets/logo_l.png" alt="eMTF Logo" srcset="" />
		<div class="contents">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import "@/styles/e_layout/_media.sass"

.basic-layout
   padding-top: var(--space-lg)

   ::v-deep
      h1
         color: var(--color-t-h)
         margin-bottom: var(--space-md)

   img
      padding: 0 var(--space-lg) var(--space-lg)

   .contents
      padding-left: var(--space-md)
      padding-right: var(--space-lg)

   @include lg
      .contents
         z-index: 2
         position: absolute
         left: 45%
         top: 25%
         width: 80%
         max-width: 50vw

      img
         right: 60%
         height: 80vh
         z-index: 1
         position: absolute
</style>