<template>
	<div>
		<div>
			<form v-on:submit.prevent="login" role="presentation" class="form">
				<div class="inputs">
					<input name="email" style="display: none" class="fakefield" />
					<b-field label="Email">
						<b-input
							v-model="email"
							type="email"
							placeholder="don@imsa.edu"
						>
						</b-input>
					</b-field>
					<b-field label="Password">
						<b-input
							v-model="password"
							value="123"
							type="password"
							placeholder="••••••••"
						></b-input>
					</b-field>
					<div>
						<a @click="isModalActive = true" class="forgotpw">
							Forgot your password?
						</a>
					</div>
				</div>
				<div class="button-container">
					<b-button
						tag="input"
						native-type="submit"
						value="Log In"
					></b-button>
					<div class="gradient-button-container">
						<b-button class="button" tag="a" href="/register"
							>Register</b-button
						>
					</div>
				</div>
			</form>
		</div>
		<b-modal v-model="isModalActive">
			<div class="">
				<p>Earl still hasn't built this feature lol. Shoot him a text.</p>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "login",
	data() {
		return {
			email: "",
			password: "",
			isModalActive: false,
		};
	},
	methods: {
		...mapActions("user", ["signIn"]),
		login() {
			this.signIn({ email: this.email, password: this.password }).then(
				() => {
					this.$router.push("/overview");
				}
			);
		},
		forgotPassword() {
			alert("ERROR");
		},
	},
};
</script>

<style lang="sass" scoped>
.form
   .inputs
      margin-bottom: var(--space-xs)
.forgotpw
   border: none

::v-deep .label
   color: var(--color-t)

.button-container
   display: flex
   justify-content: space-between
   align-items: center
</style>
