<template>
	<div class="wide-layout">
		<slot></slot>
	</div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import "@/styles/e_layout/_media.sass"

.wide-layout
   padding-top: var(--space-sm)
   margin-left: var(--space-md)
   margin-right: var(--space-md)
</style>