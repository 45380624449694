<template>
	<basic-layout>
		<div class="login-page">
			<h1>Log In</h1>
			<!-- <p>Fill in your email and password to proceed</p> -->
			<LoginComponent />
		</div>
	</basic-layout>
</template>

<script>
import LoginComponent from "@/components/Login.vue";
import BasicLayout from "./layouts/Basic";

export default {
	components: {
		LoginComponent,
		BasicLayout,
	},
};
</script>

<style lang="sass" scoped>
.login-page
   max-width: 600px
</style>