<template>
	<div class="nfts">
		<div class="container">
			<figure class="image is-3by1">
				<iframe
					class="has-ratio"
					src="https://dune.xyz/embeds/83579/166536/190243b7-8128-44a8-a83b-a957ecc9bfe"
					frameborder="20"
					height="200"
					width="600"
				></iframe>
			</figure>
		</div>
	</div>
</template>

<script>
export default {
	setup() {
		return {};
	},
};
</script>

<style lang="sass" scoped>
.nfts
   height: 100vh

.container
   background-color: white
   margin: auto
</style>