/* Application Routes */
import Account from "@/pages/application/Account";
import Assets from "@/pages/application/Assets";
import Overview from "@/pages/application/Overview";
import NFTs from "@/pages/application/NFTs";
import Components from "@/pages/application/Components";
import Settings from "@/pages/application/Settings";
import Admin from "@/pages/application/Admin";
import Amcharts from "@/components/Amcharts"; //prototype

const appRoutes = [
   {
      path: "/admin",
      name: "Admin",
      component: Admin,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/account",
      name: "Account",
      component: Account,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/assets",
      name: "Assets",
      component: Assets,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/overview",
      name: "Overview",
      component: Overview,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/nfts",
      name: "NFTs",
      component: NFTs,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/components",
      name: "Components",
      component: Components,
      meta: {
         authRequired: true,
      },
   },
   { path: "/amcharts", name: "Amcharts", component: Amcharts },
];

export default appRoutes;
