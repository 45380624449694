<template>
	<basic-layout class="welcome">
		<h1 class="brand">
			{{ companyInfo.brandName }}
			<span class="company"> by {{ companyInfo.company }} </span>
		</h1>
		<h2 v-html="companyInfo.tagline"></h2>

		<div class="gradient-button-container">
			<a href="/login" class="button">{{ companyInfo.cta }}</a>
		</div>
	</basic-layout>
</template>

<script>
import BasicLayout from "./layouts/Basic";

import companyInfo from "@/../content/companyInfo.json";

export default {
	data() {
		return {
			companyInfo: companyInfo,
		};
	},
	components: {
		BasicLayout,
	},
};
</script>

<style lang="sass" scoped>
.welcome
   h1
      font-size: 6rem
      line-height: 4rem
      margin-bottom: 0

   h2
      line-height: 1.4
      font-size: var(--d-2xl)
      font-variation-settings: "wght" 400, "wdth" 87
      margin-top: 0

.company
   white-space: nowrap
   font-size: var(--d-lg)
   text-decoration: none
   font-weight: 300

.welcome *
   // color: var(--color-t)

.gradient-button-container
   margin-top: var(--space-sm)
</style>