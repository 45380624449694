<template>
	<div v-if="holdingsData">
		<b-table
			:data="holdingsDataFormatted"
			class="b-table"
			:selected.sync="selected"
		>
			<b-table-column field="uidShort" label="UID" width="10" v-slot="props">
				{{ props.row.uid }}
			</b-table-column>
			<b-table-column field="quantity" label="QTY" v-slot="props">
				{{ props.row.quantity }}
			</b-table-column>
			<b-table-column field="display_name" label="Asset Name" v-slot="props">
				{{ props.row.display_name }}
			</b-table-column>
			<b-table-column
				field="ticker"
				label="Ticker"
				width="10"
				v-slot="props"
			>
				{{ props.row.ticker }}
			</b-table-column>
			<b-table-column field="tokenValue" label="Token Value" v-slot="props">
				{{ props.row.tokenValue }}
			</b-table-column>
			<b-table-column
				field="holdingValue"
				label="Holding Value"
				v-slot="props"
			>
				{{ props.row.holdingValue }}
			</b-table-column>
			<b-table-column v-slot="props">
				<b-button
					type="is-dark"
					@click="deleteItem(props.row)"
					icon-right="trash"
				>
				</b-button>
			</b-table-column>
		</b-table>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			selected: {},
			columns: [],
		};
	},

	computed: {
		holdingsDataFormatted() {
			if (!this.holdingsData) {
				return null;
			} else {
				let formattedData = this.holdingsData.map((holding) => {
					let formattedHolding = {
						...holding,
					};
					let usd = Intl.NumberFormat("en-US");
					formattedHolding["uidShort"] =
						"..." + holding.uid.substr(holding.uid.length - 7);

					// if (Object.keys(this.tokenData).length > 0) {
					if (this.tokenData[holding.ticker]) {
						formattedHolding["tokenValue"] =
							this.tokenData[holding.ticker].quote.USD.price;
						formattedHolding["holdingValue"] = Number(
							(
								formattedHolding["tokenValue"] *
								formattedHolding["quantity"]
							).toPrecision(4)
						);
					}

					return formattedHolding;
				});

				return formattedData;
			}
		},
	},
	props: {
		holdingsData: {
			type: Array,
			default: () => null,
		},
		tokenData: {
			type: Object,
		},
	},
	methods: {
		deleteItem(holding) {
			this.$store.dispatch("portfolio/deleteHolding", {
				uid: holding.uid,
			});
		},
	},
};
</script>

<style lang="sass" scoped>
.b-table ::v-deep
   .table
      background-color: var(--color-b-alt)
</style>