import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";

/* Authentication Routes */
import Welcome from "@/pages/Welcome";
import Register from "@/pages/Register";
import LogIn from "@/pages/LogIn";

import appRoutes from "./appRoutes.js";
import contentRoutes from "./contentRoutes.js";

Vue.use(Router);

const router = new Router({
   mode: "history",
   base: process.env.BASE_URL,
   routes: [
      /* Authentication Routes */
      {
         path: "/",
         name: "Welcome",
         component: Welcome,
      },
      { path: "/login", name: "LogIn", component: LogIn },
      { path: "/register", name: "Register", component: Register },

      ...appRoutes,
      ...contentRoutes,
   ],
});

router.beforeEach((to, from, next) => {
   const authRequired = to.matched.some((record) => record.meta.authRequired);

   // Redirect unauthenticated users to "/login" if they navigate to a page
   if (authRequired && !firebase.auth().currentUser) {
      next("/login");
   } else {
      next();
   }

   // Forward authenticated users to "/overview" if they navigate to "/login" or "/"
   if ((to.path == "/login" || to.path == "/") && firebase.auth().currentUser) {
      next("/overview");
   }
});

export default router;
