<template>
	<div class="dynamic-markdown-component content">
		<!-- see https://hmsk.github.io/frontmatter-markdown-loader/vue.html for details -->
		<!-- This component mounts `../contents/${file name which is passed through
			props}.md` as Vue component dynamically -->
		<div>
			<h2 class="title">{{ title }}</h2>
			<component :is="dynamicComponent" />
		</div>
	</div>
</template>

<script>
export default {
	props: ["fileName"],
	data() {
		return {
			title: null,
			dynamicComponent: null,
		};
	},
	created() {
		const markdown = require(`@/../content/${this.fileName}`);
		this.title = markdown.attributes.title;
		this.dynamicComponent = markdown.vue.component;

		// Use Async Components for the benefit of code splitting
		// https://vuejs.org/v2/guide/components-dynamic-async.html#Async-Components
		// this.dynamicComponent = () => import(`~/articles/${this.fileName}.md`).then(({ vue }) => vue.component
	},
};
</script>

<style lang="sass" scoped>
.dynamic-markdown-component
   max-width: 75ch
</style>
