<template>
	<div class="d-holding">
		<p class="quantity text-large">
			{{ holding.quantity }}
		</p>
		<p>
			<strong>
				{{ holding.name }}
			</strong>
			in
			{{ holding.location }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		holding: {
			type: Object,
			default: () => {
				return {
					name: "Bitcoin",
					quantity: "10",
					location: "Trust",
				};
			},
		},
	},
};
</script>

<style lang="sass" scoped>
.d-holding
   --space-md: 1.5rem

.d-holding
   padding: var(--space-md)
   border: 1px solid rgba( 56,56,116, .2 )
   border-radius: 3px
   height: 15ch
   width: 15ch
   display: grid
   place-content: center
   cursor: grab
   background-color: white
   box-shadow: var(--box-shadow)

   p
      line-height: 1
      font-size: var(--d-sm)

   .quantity
      font-size: 1.25em
      margin-bottom: var(--space-2xs)
</style>