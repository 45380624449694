import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import firebase from "firebase/app";
import "firebase/auth";

// STYLES
import "./styles/index.sass";
import "@fontsource/inconsolata/300.css";
import "@fontsource/inconsolata/variable.css";
import "@fontsource/inconsolata/variable-full.css";

import "@fontsource/domine";

// COMPONENTS
import Buefy from "buefy";
// import "buefy/dist/buefy.css";
Vue.use(Buefy, {
   defaultIconComponent: "vue-fontawesome",
   defaultIconPack: "fas",
});

// Import Font Awesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
   faCheck,
   faCheckCircle,
   faInfoCircle,
   faExclamationTriangle,
   faExclamationCircle,
   faArrowUp,
   faAngleRight,
   faAngleLeft,
   faAngleDown,
   faEye,
   faEyeSlash,
   faCaretDown,
   faCaretUp,
   faUpload,
   faPlus,
   faMinus,
   faCalendar,
   faTimesCircle,
   faAngleDoubleUp,
   faTrash,
   faSyncAlt,
   faBars,
   faTh,
   faDatabase
} from "@fortawesome/free-solid-svg-icons"; // prettier-ignore
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
   faCheck,
   faCheckCircle,
   faInfoCircle,
   faExclamationTriangle,
   faExclamationCircle,
   faArrowUp,
   faAngleRight,
   faAngleLeft,
   faAngleDown,
   faEye,
   faEyeSlash,
   faCaretDown,
   faCaretUp,
   faUpload,
   faPlus,
   faMinus,
   faCalendar,
   faTimesCircle,
   faAngleDoubleUp,
   faTrash,
   faSyncAlt,
   faBars,
   faTh,
   faDatabase
); // prettier-ignore
Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.config.productionTip = false;

const configOptions = {
   apiKey: "AIzaSyCZjs0aFcjXPdIQiGPjoxPHegiGu1mfleE",
   authDomain: "emtf-foundations-design.firebaseapp.com",
   projectId: "emtf-foundations-design",
   storageBucket: "emtf-foundations-design.appspot.com",
   messagingSenderId: "109691128820",
   appId: "1:109691128820:web:1b0ec91bb89b75651807a1",
   measurementId: "G-NG9F95H70M",
};

firebase.initializeApp(configOptions);

Vue.mixin({
   methods: {
      // TODO: Install moment.js
      // formatDate(d, f = "YYYY-MM-DD") {
      //    return moment(d).format(f);
      // },
      formatPrice(n) {
         // Create our number formatter.
         var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
         });

         return formatter.format(n); /* $2,500.00 */
      },
      formatPercent(n) {
         n = n * 100;
         const roundN = Math.round(n * 100) / 100;
         let formatted;
         if (roundN > 0) {
            formatted = `+${roundN}%`;
         } else {
            formatted = `${roundN}%`;
         }

         return formatted;
      },
   },
});

let app;
firebase.auth().onAuthStateChanged(() => {
   if (!app) {
      Vue.prototype.$env = process.env.NODE_ENV;
      app = new Vue({
         router,
         store,
         render: (h) => h(App),
      }).$mount("#app");
   }
});
