<template>
	<div>
		<tree-map></tree-map>
	</div>
</template>

<script>
import TreeMap from "@/components/dataviz/TreeMap-AllHoldings";
export default {
	components: {
		TreeMap,
	},
};
</script>

<style lang="sass" scoped>
</style>