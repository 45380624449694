import { array } from "@amcharts/amcharts4/core";
import firebase from "firebase/app";
import router from "../router";

const state = () => ({
   user: null,
   legacyUserData: null,
});

const mutations = {
   SET_USER(state, payload) {
      state.user = payload;
   },
   SET_LEGACY_USER_DATA(state, payload) {
      state.legacyUserData = payload;
   },
};

const getters = {
   getTotalEquity(s, g) {
      return g.getLegacyUserAccount ? g.getLegacyUserAccount.TotalEquity : 0;
   },
   getAccountGrowth(s, g) {
      const contribution = g.getLegacyUserAccount.TotalContribution;
      const balance = g.getTotalAccountBalance;

      return (balance - contribution) / contribution;
   },
   getManagedWalletBalance(s, g, rs, rg) {
      const managedWallet = g.getLegacyUserAccount
         ? g.getLegacyUserAccount.Accounts.find(({ Type }) => {
              return Type === "Managed Wallet";
           })
         : new Error("LegacyUserAccount does not exist");

      // if empty, return 0
      if (!managedWallet) {
         return 0;
      }
      const holdingsWithTickers = managedWallet.Holdings.map((x) => {
         return {
            ...x,
            Price: rg["market/getLatestQuotes"](x.Ticker).quote.USD.price,
         };
      });

      const balance = holdingsWithTickers.reduce((p, x) => {
         console.log(`${x.Ticker} Balance: ${x.Quantity * x.Price}`);
         return p + x.Quantity * x.Price;
      }, 0);

      return balance;
   },
   getRole(s, g) {
      return g.getLegacyUserAccount ? g.getLegacyUserAccount.Role : undefined;
   },
   getUser(state) {
      return state.user;
   },
   getLegacyUserAccount(state) {
      if (state.legacyUserData && state.user.email) {
         const nodeToReturn = state.legacyUserData.find(({ Email }) => {
            return Email === state.user.email;
         });

         return nodeToReturn;
      }
   },
   getTotalAccountBalance(state, getters, rootState, rootGetters) {
      if (state.legacyUserData && state.user.email) {
         const accountEquity = getters.getLegacyUserAccount.Accounts.find(({ Type }) => {
            return Type === "eMTF Digital";
         }).Equity;

         // const portfolioTotal = rootGetters["portfolio/getTotalAssets"];
         const portfolioTotal = 42000;
         return accountEquity * portfolioTotal;
      }
   },
};

const actions = {
   async fetchLegacyUserData({ commit }) {
      const ref = firebase.database().ref("LegacyData/Users");

      await ref.get().then((snapshot) => {
         if (snapshot.exists()) {
            commit("SET_LEGACY_USER_DATA", snapshot.val());
         } else {
            console.log("No data available");
         }
      });
   },
   createAccount({ commit, dispatch }, payload) {
      return firebase
         .auth()
         .createUserWithEmailAndPassword(payload.email, payload.password)
         .then((response) => {
            commit("SET_USER", response.user);

            const db = firebase.database().ref("users/");
            const userKey = db.push().key;
            let obj = {};
            obj[userKey] = {
               email: payload.email,
               uid: userKey,
               role: "Limited Partner",
               phone: payload.phone,
               name: payload.name,
            };
            db.update(obj).then(() => {
               dispatch(
                  "setNotification",
                  {
                     message: "Welcome 🙂",
                     type: "success",
                  },
                  { root: true }
               );
            });
         })
         .catch((error) => {
            dispatch("setNotification", { message: error.message, type: "error" }, { root: true });
         });
   },

   signIn({ commit, dispatch }, payload) {
      return firebase
         .auth()
         .signInWithEmailAndPassword(payload.email, payload.password)
         .then((response) => {
            commit("SET_USER", response.user);
            dispatch("fetchLegacyUserData");
            dispatch("setError", null);
         })
         .catch((error) => {
            dispatch("setError", error.message);
         });
   },
   signOut({ commit, dispatch }) {
      firebase
         .auth()
         .signOut()
         .then(() => {
            commit("SET_USER", null);
            router.push("/");
         })
         .catch((error) => {
            dispatch("setError", error.message);
         });
   },
   refreshAuth({ commit, dispatch }) {
      firebase.auth().onAuthStateChanged((user) => {
         if (user) {
            commit("SET_USER", user);
            dispatch("fetchLegacyUserData");
         } else {
            commit("SET_USER", null);
         }
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
