<template>
	<div class="treemap">
		<div class="amchart" :ref="name"></div>
	</div>
</template>

<script>
/*eslint-disable*/

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import colortheme from "@amcharts/amcharts4/themes/dark";

// import getTreeMapData from "@/components/dataviz/TreeMapData.js";

export default {
	data() {
		return {
			sample_data: [
				{
					Display_Name: "Cardano",
					Ticker: "ADA",
					Quantity: "5597",
					Location: "Yoroi Wallet",
					Alternate_Unit: "",
					Crypto: "checked",
					value_btc: 0.23759265,
					value_usd: 8563.41,
				},
				{
					Display_Name: "Ethereum",
					Ticker: "ETH",
					Quantity: "0.1296",
					Location: "Trust Wallet - RMPL",
					Alternate_Unit: "",
					Crypto: "checked",
					value_btc: 0.0090417,
					value_usd: 326.61,
				},
				{
					Display_Name: "RMPL",
					Ticker: "RMPL",
					Quantity: "850.932",
					Location: "Trust Wallet - RMPL",
					Alternate_Unit: "",
					Crypto: "checked",
					value_btc: 0.01475516088,
					value_usd: 544.48,
				},
			],
			loaded_data: "",
		};
	},
	props: {
		name: {
			type: String,
			default: "treemap",
		},
		inputHoldings: {
			type: Object,
			default: null,
		},
		config: {
			type: Object,
			default: function () {
				return {
					sort: "none",
					showLoans: false,
				};
			},
		},
	},
	created() {},
	async mounted() {
		// await this.getData(); //for production
		this.loaded_data = this.sample_data; //for development

		var chart = am4core.create(this.$refs[this.name], am4charts.TreeMap);

		chart.data = this.loaded_data;
		chart.dataFields.name = "Display_Name";
		chart.dataFields.value = "value_usd";

		chart.padding(0, 0, 0, 0);

		/* Configure top-level series */
		var level1 = chart.seriesTemplates.create("0");

		/* Add bullet labels */
		var level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
		level1_bullet.locationY = 0.5;
		level1_bullet.locationX = 0.5;
		level1_bullet.label.text = "{name}";
		level1_bullet.label.fill = am4core.color("#fff");

		var level1_column = level1.columns.template;
		level1_column.tooltipText = "{name}: ${value.formatNumber('#.00')}";

		var level2 = chart.seriesTemplates.create("1");
		var level2_column = level2.columns.template;
		level2_column.column.cornerRadius(5, 5, 5, 5);
		level2_column.tooltipText = "{name}: ${value}";

		this.chart = chart;
	},
	methods: {
		async getData() {
			return getTreeMapData(this.inputHoldings).then((r) => {
				this.loaded_data = r;
			});
		},
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
};
</script>

<style lang="sass" scoped>
.amchart
   height: 100%
   min-height: 500px
   width: 100%

.treemap
   & > *
      margin-bottom: var(--space-sm)
</style>