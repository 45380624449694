<template>
	<div class="card-container">
		<div class="card">
			<p class="body-1 portfolio">{{ holding.portfolio }}</p>
			<div class="card-left">
				<div class="logo">
					<img :src="holding.image" alt="currency logo" />
				</div>
				<h4 class="value">{{ formatPrice(holding.value) }}</h4>
			</div>
			<div class="card-right">
				<p class="title">{{ holding.display_name }}</p>
				<p class="caption">{{ holding.quantity }} {{ holding.ticker }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		holding: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="sass" scoped>
// TODO: card-left should stay the same

h4
   color: rgba( 56,56,116, 1 )
   font-weight: 400
   letter-spacing: -0.44px
   font-size: 34px
   // font-family: var(--font-secondary)

p.body-1
   font-size: var(--d-sm)

p.title
   font-family: var(--font-secondary)
   font-weight: 400
   color: rgba( 56,56,116, 1 )
   font-size: var(--d-m)
   margin: 0

p.caption
   font-size: var(--d-sm)
   letter-spacing: 0.4px
   opacity: .6
   margin: 0

.card
   background-color: white
   padding: var(--space-xs) var(--space-sm) var(--space-xs) 0
   height: 88px //remove later
   // width: 350px //remove later
   display: flex
   align-items: center
   justify-content: space-between
   box-shadow: none
   position: relative

   border-left: 2rem solid #F2F2F2

   .portfolio
      position: absolute // Abs positioning makes it not take up vert space
      bottom: 0
      left: 0
      margin-left: -1.5rem // Based on the size of card's border
      transform: rotate(-90deg)
      transform-origin: 0 0 // Rotate from top left corner (not default)

   .card-left
      display: flex
      flex-grow: 1
      justify-content: space-evenly
      // padding-left: var(--space-2xs)
      padding-right: var(--space-2xs)

      .logo
         width: 50px
         margin-right: var(--space-2xs)
         margin-left: var(--space-2xs)
         display: grid
         place-content: center

   .card-right
      flex-grow: 1

.card-container
   border-radius: .25rem
   background-color: white
   border: 1px solid rgba( 56,56,116, .2 )
   box-shadow: var(--box-shadow)
</style>
