<template>
	<div class="add-account" @keyup.enter="submit">
		<h2>Add Account</h2>
		<b-field label="Display Name">
			<b-input v-model="formData.DisplayName"></b-input>
		</b-field>
		<b-field label="URL">
			<b-input v-model="formData.Url"></b-input>
		</b-field>
		<b-field label="Wallet Address">
			<b-input v-model="formData.WalletAddress"></b-input>
		</b-field>
		<b-field label="Location" expanded>
			<b-autocomplete
				v-model="formData.Location"
				:data="filteredDataArray"
				field="name"
				@select="(option) => (selected = option)"
			>
			</b-autocomplete>
		</b-field>
		<b-button @click="submit" class="is-primary" icon-left="plus"
			>Add Account</b-button
		>
	</div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

import { mapActions, mapGetters } from "vuex";

export default {
	data() {
		return {
			formData: {
				DisplayName: "",
				Url: "",
				WalletAddress: "",
				Location: "",
			},
		};
	},
	computed: {
		...mapGetters("portfolio", ["getLocations"]),
	},

	methods: {
		...mapActions("portfolio", ["addAccount"]),

		submit() {
			this.addAccount(this.formData).then(() => {
				// clear form
				this.formData = {
					DisplayName: "",
					Url: "",
					WalletAddress: "",
				};
			});
		},
	},
};
</script>

<style lang="sass" scoped>
.add-account
   background-color: var(--color-b-alt)
   padding: var(--space-xs)

   ::v-deep
      label
         font-weight: 400
         color: var(--color-t-c)
      .disabled
         color: var(--color-b-alt-2)
      .dropdown-item *
         color: var(--color-b)
</style>