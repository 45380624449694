<template>
	<Layout>
		<markdown />
	</Layout>
</template>

<script>
import Layout from "../layouts/Content";
import markdown from "@/../content/fees.md";

export default {
	components: {
		Layout,
		markdown: markdown.vue.component,
	},
};
</script>

<style lang="sass" scoped>
</style>