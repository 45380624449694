<template>
	<div>
		<!-- docs: https://github.com/johndatserakis/vue-navigation-bar -->
		<vue-navigation-bar
			v-if="this.getUser"
			class="vnb"
			:options="navbarOptions"
			@vnb-item-clicked="signOut"
		/>
		<div class="no_user" v-else></div>
	</div>
</template>
<script>
import VueNavigationBar from "vue-navigation-bar";
import { mapGetters, mapActions } from "vuex";

export default {
	computed: {
		...mapGetters("user", ["getUser"]),
	},
	methods: {
		signOut(text) {
			if (text == "Sign Out") {
				this.$store.dispatch("user/signOut");
			}
		},
	},
	components: {
		VueNavigationBar,
	},
	beforeUpdate() {
		if (this.getUser) {
			this.navbarOptions.menuOptionsRight[0].subMenuOptions[0].text =
				this.getUser.email;
		}
	},
	data() {
		return {
			navbarOptions: {
				elementId: "main-navbar",
				isUsingVueRouter: true,
				mobileBreakpoint: 992,
				brandImagePath: "./",
				brandImage: require("@/assets/logo_bordered.svg"),
				brandImageAltText: "brand-image",
				collapseButtonOpenColor: "#ffffff",
				collapseButtonCloseColor: "#ffffff",
				showBrandImageInMobilePopup: true,
				ariaLabelMainNav: "Main Navigation",
				tooltipAnimationType: "shift-away",
				tooltipPlacement: "bottom",
				menuOptionsLeft: [
					{
						type: "link",
						text: "Assets",
						arrowColor: "#ffffff",
						path: { name: "Assets" },
					},
					{
						type: "link",
						text: "Updates",
						path: { name: "Updates" },
					},
					{
						type: "link",
						text: "Info (Coming Soon!)",
						arrowColor: "#ffffff",
						subMenuOptions: [
							// {
							// 	type: "link",
							// 	text: "Strategy",
							// 	path: { name: "Strategy" },
							// },
							// {
							// 	type: "link",
							// 	text: "Philosophy",
							// 	path: { name: "Philosophy" },
							// },
							// {
							// 	type: "link",
							// 	text: "Resources",
							// 	path: { name: "Resources" },
							// },
							// {
							// 	type: "link",
							// 	text: "Fees",
							// 	path: { name: "Fees" },
							// },
							// {
							// 	type: "link",
							// 	text: "Withdraw & Deposit",
							// 	path: { name: "Withdraw & Deposit" },
							// },
						],
					},
				],
				menuOptionsRight: [
					{
						type: "link",
						arrowColor: "#ffffff",
						text: "Account",
						subMenuOptions: [
							{
								type: "link",
								text: "{{ user email}}",
								path: "",
							},
							{
								type: "link",
								text: "Settings",
								path: { name: "Settings" },
							},
							{
								isLinkAction: true,
								type: "link",
								text: "Sign Out",
								path: "",
							},
						],
					},
				],
			},
		};
	},
};
</script>

<style lang="sass" scoped >
@import "../../node_modules/vue-navigation-bar/dist/vue-navigation-bar.css"

hr
   border: 1px solid white
   opacity: .25
   width: 100vw
   margin: 0

::v-deep .vnb
   background: var(--color-b)
   border-bottom: var(--color-t-c) solid 1px

   &__sub-menu-options
      background-color: var(--color-b-alt)

      &__option__link:hover
         background-color: var(--color-b-alt)

   .tippy-box
      box-shadow: none
      background-color: var(--color-b-alt)

   .vnb__sub-menu-options__option__link
      border-left: 1px solid var(--color-b-alt)

   .vnb__sub-menu-options__option__link:hover
      border-left: 1px solid var(--color-p)

   *, svg
      color: var(--color-t)

.no_user
   min-height: var(--space-md)
   border-bottom: #fff solid 2px
   opacity: .2
</style>