<template>
	<Layout class="section container">
		<p>Coming Soon!</p>
		<!-- <section class="holdings">
			<tree-map />

			<holdings-table
				:holdingsData="Object.values(getHoldings)"
				:tokenData="getTokenData"
			/>
		</section>
		<section class="delta">
			<img src="@/assets/delta.png" alt="" srcset="" />
			<h2>Requirements:</h2>
			<ul>
				<li>Fully public management team</li>
				<li>Holdings > 10% of the fund must have a mcap > $10 billion</li>
				<li>Assets have been held by eMTF for > 6 months</li>
			</ul>
		</section> -->
	</Layout>
</template>

<script>
/*eslint-disable */
import TreeMap from "@/components/dataviz/TreeMap-AllHoldings";
import Layout from "../layouts/Basic";

import HoldingsTable from "@/components/HoldingsTable.vue";
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters("market", ["getTokenData", "getCoinsList"]),
		...mapGetters("portfolio", ["getTotalAssets", "getHoldings"]),
	},

	components: {
		TreeMap,
		HoldingsTable,
		Layout,
	},
};
</script>

<style lang="sass" scoped>
.delta
   // background-color: whitesmoke
</style>