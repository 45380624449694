<!-- https://console.firebase.google.com/project/emtf-foundations-design/authentication/users  -->

<template>
	<div class="register">
		<form v-on:submit.prevent="handleSubmission">
			<div class="inputs">
				<b-field label="Name">
					<b-input v-model="n" placeholder="Earl Man" />
				</b-field>
				<b-field label="Email">
					<b-input v-model="email" placeholder="don@imsa.edu" />
				</b-field>
				<b-field label="Phone" message="format: (111) 111-1111">
					<b-input v-model="phone" placeholder="(630) 123-4567" />
				</b-field>
				<b-field label="Password" :type="passwordState.type">
					<b-input
						v-model="password"
						type="password"
						placeholder="Password"
						id="password"
					/>
				</b-field>
				<b-field
					label="Confirm Password"
					:type="passwordState.type"
					:message="passwordState.message"
				>
					<b-input
						type="password"
						v-model="confirmPassword"
						placeholder="Confirm Password"
					/>
				</b-field>
			</div>
			<b-button
				tag="input"
				native-type="submit"
				value="Create Account"
				type="submit"
			></b-button>
		</form>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "CreateAccount",
	data() {
		return {
			email: "",
			n: "",
			password: "",
			phone: "",
			confirmPassword: "",
			passwordState: {
				type: "",
				message: "",
			},
		};
	},
	props: ["value"],
	methods: {
		handleSubmission() {
			if (this.checkPassword()) {
				this.$store
					.dispatch("user/createAccount", {
						email: this.email,
						password: this.password,
						name: this.n,
						phone: this.phone,
					})
					.then(() => {
						this.$router.push("/overview");
					});
			}
		},
		checkPassword() {
			if (this.password === this.confirmPassword) return true;
			else {
				this.passwordState = {
					type: "is-danger",
					message: "Passwords do not match",
				};
				return false;
			}
		},
	},
};
</script>
<style lang="sass" scoped>
.inputs
   margin-bottom: var(--space-xs)

::v-deep .label
   color: var(--color-t)
</style>