<template>
	<Layout>
		<p>
			Form's a bit janky right now but didn't wanna spend too much time on
			implementation. We'll get an integrated form at some point
		</p>
		<iframe
			src="https://docs.google.com/forms/d/e/1FAIpQLSeRhhGtHka8U1-7ZH85Uy-FYimMCdyEr5NEHJO85kNyuz2oow/viewform?embedded=true"
			width="600"
			height="800"
			frameborder="0"
			marginheight="0"
			marginwidth="0"
			>Loading…</iframe
		>
		<markdown />
	</Layout>
</template>

<script>
import Layout from "../layouts/Content";
import markdown from "@/../content/withdrawdeposit.md";

export default {
	components: {
		Layout,
		markdown: markdown.vue.component,
	},
};
</script>

<style lang="sass" scoped>
</style>