<template>
	<Layout>
		<p>Coming Soon!</p>
	</Layout>
</template>

<script>
import Layout from "../layouts/Basic";

export default {
	components: {
		Layout,
	},
};
</script>

<style lang="sass" scoped>
</style>