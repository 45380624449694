<template>
	<Layout class="admin-page">
		<!-- <b-skeleton width="100%" height="900px" :animated="animated"></b-skeleton> -->
		<div>
			<div class="buttons">
				<b-button
					class="update-button"
					@click="updateFirebaseWithMarketData"
					icon-left="sync-alt"
					>Update Market Data</b-button
				>
				<b-button
					tag="a"
					href="https://console.firebase.google.com/project/emtf-foundations-design/database/emtf-foundations-design-default-rtdb/data"
					target="_blank"
					icon-left="database"
					>Open Firebase Console</b-button
				>
			</div>
			<div>
				<p>Total Portfolio Value: {{ formatPrice(getTotalAssets()) }}</p>
			</div>
			<AddHolding />
		</div>
		<!-- <b-skeleton width="100%" height="900px" :animated="animated"></b-skeleton> -->
		<div>
			<!-- <Cards /> -->
			<HoldingsView />
		</div>
		<hr />
		<AssetKanban class="assetkanban" />
	</Layout>
</template>

<script>
import Layout from "../layouts/Wide";
import HoldingsView from "@/components/HoldingsView";
import AddHolding from "@/components/AddHolding";
import AssetKanban from "@/components/AssetKanban.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	methods: {
		...mapActions("market", ["updateFirebaseWithMarketData"]),
		...mapGetters("portfolio", ["getTotalAssets"]),
	},
	components: {
		Layout,
		HoldingsView,
		AddHolding,
		AssetKanban,
	},
};
</script>

<style lang="sass" scoped>
.admin-page
   display: grid
   grid-template-columns: 33% 64%
   grid-gap: var(--space-sm)

   .b-skeleton
      margin: 0

   .update-button
      // margin-bottom: var(--space-xs)

   .assetkanban
      grid-column: 1/-1
</style>