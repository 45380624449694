import axios from "axios";
import firebase from "firebase/app";

const state = () => ({
   marketData: {},
   tokenData: {},
   coinsList: [],
});

const mutations = {
   SET_TOKEN_DATA(state, payload) {
      state.tokenData = payload;
   },
   SET_COINS_LIST(state, payload) {
      state.coinsList = payload;
   },
   SET_MARKET_DATA(state, payload) {
      state.marketData = payload;
   },
};

const getters = {
   getTokenData(state) {
      return state.tokenData;
   },
   getCoinsList(state) {
      return state.coinsList;
   },
   getMetadata: (state) => (ticker) => {
      return state.marketData.data.Metadata[ticker];
   },
   getLatestQuotes: (state) => (ticker) => {
      const latestQuote = state.marketData.data.LatestQuotes[ticker];
      if (!latestQuote) {
         console.warn("Ticker data not found");
      }
      return latestQuote;
   },
};

const actions = {
   async fetchMarketData({ commit }) {
      const marketRef = firebase.database().ref("market");
      return await marketRef.get().then((snapshot) => {
         if (snapshot.exists()) {
            commit("SET_MARKET_DATA", snapshot.val());
         } else {
            console.log("No data available");
         }
      });
   },
   async updateFirebaseWithMarketData({ dispatch }) {
      const url = `/.netlify/functions/update-token-data`;

      return await axios.get(url).then(
         (r) => {
            dispatch("fetchMarketData");
            dispatch(
               "setNotification",
               {
                  message: JSON.stringify(r.data),
                  type: "success",
               },
               { root: true }
            );
         },
         (e) => {
            dispatch(
               "setNotification",
               {
                  message: JSON.stringify(e),
                  type: "error",
               },
               { root: true }
            );
         }
      );
   },

   async fetchLatestQuotes({ commit, state, dispatch }) {
      const holdingsTickers = [];

      for (let key in state.holdings) {
         holdingsTickers.push(state.holdings[key].ticker);
      }

      const holdingsTickersFormatted = holdingsTickers.join(",");

      const PROXY = "/.netlify/functions/cors";
      const url = `${PROXY}/https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest`;

      return await axios
         .get(url, {
            params: {
               symbol: holdingsTickersFormatted,
            },
         })
         .then(
            (response) => {
               commit("SET_TOKEN_DATA", response.data.data);
               return response.data.data;
            },
            (e) => {
               dispatch(
                  "setNotification",
                  {
                     message: JSON.stringify(e),
                     type: "error",
                  },
                  { root: true }
               );
            }
         );
   },

   // async fetchCoinsList({ commit, dispatch }) {
   //    const PROXY = "/.netlify/functions/cors";
   //    const url = `${PROXY}/https://pro-api.coinmarketcap.com/v1/cryptocurrency/map`;

   //    return await axios.get(url).then(
   //       (r) => {
   //          commit("SET_COINS_LIST", r.data.data);
   //          return r.data.data;
   //       },
   //       (e) => {
   //          dispatch(
   //             "setNotification",
   //             {
   //                message: JSON.stringify(e),
   //                type: "error",
   //             },
   //             { root: true }
   //          );
   //       }
   //    );
   // },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
