<template>
	<div class="container">
		<h3>All Routes:</h3>
		<div class="routes">
			<span v-for="(item, i) in items" :key="i" class="i">
				<a :href="item.path" :to="item.path">
					{{ item.name }}&nbsp;|&nbsp;</a
				>
			</span>
		</div>
	</div>
</template>
<script>
export default {
	created() {
		this.$router.options.routes.forEach((route) => {
			this.items.push({
				name: route.name,
				path: route.path,
			});
		});
	},
	data() {
		return {
			items: [],
		};
	},
};
</script>
<style lang="sass" scoped>
.routes
   display: flex
   flex-wrap: wrap

.i
   white-space: nowrap
</style>