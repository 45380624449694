<template>
	<tree-map-base
		class="treemap-base"
		name="TreeMapAllHoldings"
		:inputHoldings="inputHoldings"
	/>
</template>

<script>
/*eslint-disable*/

import TreeMapBase from "@/components/dataviz/TreeMap-Base.vue";

export default {
	computed: {
		// filter data here
		inputHoldings() {
			// return this.$static.collateralHoldings;
		},
	},
	components: {
		TreeMapBase,
	},
	mounted() {
		// console.table(this.$static.allHoldings.edges);
	},
};
</script>

<style lang="sass" scoped>
.treemap-base
   height: 1000px
</style>