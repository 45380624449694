import firebase from "firebase/app";

const state = () => ({
   holdings: {},
   accounts: {},
   locations: {},
});

const mutations = {
   SET_HOLDINGS(state, payload) {
      state.holdings = payload;
   },
   SET_LOCATIONS(state, payload) {
      state.locations = payload;
   },
   SET_ACCOUNTS(state, payload) {
      state.accounts = payload;
   },
};

const getters = {
   getTotalAssets(state, getters, rootState, rootGetters) {
      let total = 0;

      for (const [key, val] of Object.entries(state.holdings)) {
         //get latest quotes
         const marketPrice = rootGetters["market/getLatestQuotes"](val.Ticker).quote.USD.price;
         total += parseFloat(val.Quantity * marketPrice);
      }

      return Math.round(total * 100) / 100;
   },

   getHoldings(state) {
      return state.holdings;
   },
   getLocations(state) {
      return state.locations;
   },
   getAccounts(state) {
      return state.accounts;
   },
};

const actions = {
   async fetchPortfolio({ commit }) {
      const ref = firebase.database().ref("portfolio");

      await ref.get().then((snapshot) => {
         if (snapshot.exists()) {
            console.log(snapshot.val());
            commit("SET_HOLDINGS", snapshot.val().holdings);
            commit("SET_LOCATIONS", snapshot.val().locations);
            commit("SET_ACCOUNTS", snapshot.val().accounts);
         } else {
            console.log("No data available");
         }
      });
   },
   async deleteHolding({ dispatch }, { uid }) {
      const holdingRef = firebase
         .database()
         .ref("portfolio/holdings")
         .child(uid);

      await holdingRef.remove().then(
         (r) => {
            dispatch(
               "setNotification",
               {
                  message: "item deleted",
                  type: "success",
               },
               { root: true }
            );
            dispatch("fetchHoldings");
         },
         (e) => {
            dispatch(
               "setNotification",
               {
                  message: JSON.stringify(e),
                  type: "error",
               },
               { root: true }
            );
         }
      );
   },
   async fetchHoldings({ commit }) {
      const holdingsRef = firebase.database().ref("portfolio/holdings");

      await holdingsRef.get().then((snapshot) => {
         if (snapshot.exists()) {
            commit("SET_HOLDINGS", snapshot.val());
         } else {
            console.log("No data available");
         }
      });
   },
   async addHolding({ dispatch }, holding) {
      const db = firebase.database().ref("portfolio/holdings");
      const holdingKey = db.push().key;
      let updates = {};
      updates[holdingKey] = holding;
      updates[holdingKey].uid = holdingKey; //add firebase-created uid as a property
      return db.update(updates).then(
         () => {
            dispatch("fetchHoldings");
            dispatch(
               "setNotification",
               {
                  message: "holding added",
                  type: "success",
               },
               { root: true }
            );
         },
         (e) => {
            dispatch(
               "setNotification",
               {
                  message: e,
                  type: "error",
               },
               { root: true }
            );
         }
      );
   },
   async addLocation({ dispatch }, location) {
      const db = firebase.database().ref("portfolio/locations/");
      const locationKey = db.push().key;
      let updates = {};
      updates[locationKey] = location;
      updates[locationKey].uid = locationKey; //add firebase-created uid as a property
      return db.update(updates).then(
         () => {
            dispatch("fetchLocations");
            dispatch(
               "setNotification",
               {
                  message: "location added",
                  type: "success",
               },
               { root: true }
            );
         },
         (e) => {
            dispatch(
               "setNotification",
               {
                  message: e,
                  type: "error",
               },
               { root: true }
            );
         }
      );
   },
   async deleteLocation({ dispatch }, { uid }) {
      //
   },
   async fetchLocations({ commit }) {
      const locationsRef = firebase.database().ref("portfolio/locations/");

      await locationsRef.get().then((snapshot) => {
         if (snapshot.exists()) {
            commit("SET_LOCATIONS", snapshot.val());
         } else {
            console.log("No data available");
         }
      });
   },
   async addAccount({ dispatch }, account) {
      const db = firebase.database().ref("portfolio/accounts/");
      const accountKey = db.push().key;
      let updates = {};
      updates[accountKey] = account;
      updates[accountKey].uid = accountKey; //add firebase-created uid as a property
      return db.update(updates).then(
         () => {
            dispatch("fetchAccounts");
            dispatch(
               "setNotification",
               {
                  message: "account added",
                  type: "success",
               },
               { root: true }
            );
         },
         (e) => {
            dispatch(
               "setNotification",
               {
                  message: e,
                  type: "error",
               },
               { root: true }
            );
         }
      );
   },
   async deleteAccount({ dispatch }, { uid }) {
      //
   },
   async fetchAccounts({ commit }) {
      const locationsRef = firebase.database().ref("portfolio/accounts/");

      return await locationsRef.get().then((snapshot) => {
         if (snapshot.exists()) {
            commit("SET_ACCOUNTS", snapshot.val());
         } else {
            console.log("No data available");
         }
      });
   },
   async deleteItem({ dispatch }, { uid, fbRef }) {
      console.log(fbRef);
      const itemRef = firebase
         .database()
         .ref(fbRef)
         .child(uid);

      return await itemRef.remove().then(
         () => {
            dispatch(
               "setNotification",
               {
                  message: "item deleted",
                  type: "success",
               },
               { root: true }
            );
            dispatch("fetchPortfolio");
         },
         (e) => {
            dispatch(
               "setNotification",
               {
                  message: JSON.stringify(e),
                  type: "error",
               },
               { root: true }
            );
         }
      );
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
