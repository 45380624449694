<template>
	<div>
		<h2 class="title">{{ title }}</h2>
		<b-table :data="dataArray">
			<b-table-column
				v-for="column in columns"
				:key="column.field"
				:field="column.field"
				:label="column.label"
				v-slot="props"
			>
				{{ props.row[column.field] }}
			</b-table-column>
			<b-table-column v-slot="props" v-if="showDelete">
				<b-button @click="deleteItem(props.row)" icon-right="trash">
				</b-button>
			</b-table-column>
		</b-table>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: () => {
				"General Table";
			},
		},
		data: {
			type: Object,
			required: true,
		},
		fbRef: {
			type: String,
			required: true,
		},
		showDelete: {
			type: Boolean,
			default: () => {
				return false;
			},
		},
	},
	computed: {
		dataArray() {
			return Object.values(this.data);
		},
		columns() {
			return this.dataProperties.map((x) => {
				return {
					field: x,
					label: x,
				};
			});
		},
		dataProperties() {
			// iterate through first 10 objects and return the property keys
			// (iterating through 10 in case data is not set on some of the objects)
			const x = 10;
			const dataProperties = [];

			Object.keys(this.data)
				.slice(0, x)
				.forEach((x) => {
					Object.keys(this.data[x]).forEach((x) => {
						if (dataProperties.includes(x) === false)
							dataProperties.push(x);
					});
				});

			return dataProperties;
		},
	},
	methods: {
		deleteItem(item) {
			this.$store.dispatch("portfolio/deleteItem", {
				uid: item.uid,
				fbRef: this.fbRef,
			});
		},
	},
};
</script>

<style lang="sass" scoped>
::v-deep .table
   background: none
   border-color: none

   td, th
      border-color: var(--color-t)
      color: var(--color-t)
</style>