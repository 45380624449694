<template>
	<div class="theme-toggle">
		<b-field>
			<b-switch
				@input="toggleTheme"
				v-model="userTheme"
				true-value="light-theme"
				false-value="dark-theme"
				>🌙/☀️</b-switch
			>
		</b-field>
	</div>
</template>

<script>
// source: https://dev.to/tqbit/create-your-own-dark-mode-toggle-component-with-vue-js-1284

export default {
	mounted() {
		const initUserTheme = this.getMediaPreference();
		this.setTheme(initUserTheme);
	},
	data() {
		return {
			userTheme: "dark-theme",
		};
	},
	methods: {
		toggleTheme() {
			const activeTheme = localStorage.getItem("user-theme");
			if (activeTheme === "light-theme") {
				this.setTheme("dark-theme");
			} else {
				this.setTheme("light-theme");
			}
		},
		setTheme(theme) {
			localStorage.setItem("user-theme", theme);
			this.userTheme = theme;
			document.documentElement.className = theme;
		},
		getMediaPreference() {
			const hasLightPreference = window.matchMedia(
				"(prefers-color-scheme: light)"
			).matches;
			if (hasLightPreference) {
				return "light-theme";
			} else {
				return "dark-theme";
			}
		},
	},
};
</script>

<style lang="sass" scoped>
</style>