<template>
	<div class="cards-container" v-if="view === 'cards'">
		<!-- <Card v-for="h in sampleHoldings" :key="h.uid" :holding="h" /> -->
		<Card v-for="h in holdingsDataFormatted" :key="h.uid" :holding="h" />
	</div>
</template>

<script>
import Card from "./Card";

export default {
	data() {
		return {
			view: "cards",
			sampleHoldings: [
				{
					display_name: "Ethereum",
					quantity: 0.5,
					value: 2810,
					uid: "-M001",
					ticker: "ETH",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
					// image: "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880", // (large0
				},
				{
					display_name: "Polkadot",
					quantity: 23.816,
					value: 1218,
					uid: "-M002",
					ticker: "DOT",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/12171/small/aJGBjJFU_400x400.jpg?1597804776",
				},
				{
					display_name: "Terra",
					quantity: 17.727,
					value: 958,
					uid: "-M003",
					ticker: "LUNA",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/8284/small/luna1557227471663.png?1567147072",
				},
				{
					display_name: "Bitcoin",
					quantity: 0.041,
					value: 2806,
					uid: "-M004",
					ticker: "BTC",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579",
				},
				{
					display_name: "Sushi",
					quantity: 117.48,
					value: 1431,
					uid: "-M005",
					ticker: "SUSHI",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/12271/small/512x512_Logo_no_chop.png?1606986688",
				},
				{
					display_name: "Uniswap",
					quantity: 54.504,
					value: 1513,
					uid: "-M006",
					ticker: "UNI",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/12504/small/uniswap-uni.png?1600306604",
				},
				{
					display_name: "Chainlink",
					quantity: 17.727,
					value: 958,
					uid: "-M007",
					ticker: "LINK",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700",
				},
				{
					display_name: "Cardano",
					quantity: 597,
					value: 1352,
					uid: "-M008",
					ticker: "ADA",
					portfolio: "Sample",
					image: "https://assets.coingecko.com/coins/images/975/small/cardano.png?1547034860",
				},
			],
		};
	},
	computed: {
		holdingsDataFormatted() {
			const holdings = this.$store.getters["portfolio/getHoldings"];

			const formattedHoldings = Object.values(holdings).map((h) => {
				const metadata = this.$store.getters["market/getMetadata"](
					h.Ticker
				);
				const latestQuotes = this.$store.getters["market/getLatestQuotes"](
					h.Ticker
				);

				return {
					display_name: h.DisplayName,
					quantity: h.Quantity,
					value: latestQuotes.quote.USD.price,
					uid: h.uid.substr(h.uid.length - 9),
					ticker: h.Ticker,
					portfolio: "Delta",
					image: metadata.logo,
				};
			});

			return formattedHoldings;
		},
	},

	components: {
		Card,
	},
};
</script>

<style lang="sass" scoped>
@import "@/styles/e_layout/_media.sass"

.cards-container
   --color-t: #383874

   width: 100%
   display: flex
   flex-wrap: wrap
   grid-gap: var(--space-xs)

   @include xl
      grid-template-columns: repeat(4, 1fr)
</style>
