<template>
	<div id="app">
		<h2>Asset Kanban</h2>
		<div class="kanban-container">
			<div class="group" v-for="p in portfolio" :key="p.name">
				<draggable
					:list="p.holdings"
					group="portfolio"
					@change="log"
					class="kanban"
				>
					<d-holding
						class="holding"
						v-for="(element, id) in p.holdings"
						:key="id"
						:holding="element"
					>
						{{ element }}
					</d-holding>
				</draggable>
			</div>
			<div>
				<button class="button" @click="savePortfolio">
					Save Portfolio
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import draggable from "vuedraggable";
import dHolding from "./AssetKanbanHolding.vue";

export default {
	name: "App",
	data() {
		return {
			portfolio: [
				{
					name: "delta",
					holdings: [
						{
							name: "Bitcoin",
							quantity: 0.856,
							location: "Metamask",
						},
						{
							name: "Cardano",
							quantity: 0.856,
							location: "BlockFi",
						},
						{
							name: "Ethereum",
							quantity: 2.377,
							location: "Main",
						},
					],
				},
				{
					name: "theta",
					holdings: [
						{
							name: "Luna",
							quantity: 0.856,
							location: "Metamask",
						},
						{
							name: "Chiliz",
							quantity: 0.856,
							location: "BlockFi",
						},
					],
				},
			],
		};
	},
	components: {
		draggable,
		dHolding,
	},
	methods: {
		add: function () {
			this.list.push({ name: "Juan" });
		},
		replace: function () {
			this.list = [{ name: "Edgard" }];
		},
		clone: function (el) {
			return {
				name: el.name + " cloned",
			};
		},
		log: function (evt) {
			window.console.log(evt);
		},
		savePortfolio: function () {
			console.log(this.portfolio);

			// save into firebase vue-portfolio-plans
		},
	},
};
</script>

<style lang="sass">
.group
   padding: 1rem
   border: 1px solid rgba( 56,56,116, .2 )

.kanban-container
   display: grid
   grid-gap: var(--space-xs)

.kanban
   display: flex
   grid-gap: var(--space-xs)
</style>
