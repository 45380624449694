import Vue from "vue";
import Vuex from "vuex";
import { ToastProgrammatic as Toast } from "buefy";

import portfolio from "./portfolio";
import user from "./user";
import market from "./market";

Vue.use(Vuex);

const initialState = () => {
   return {
      notification: null,
   };
};

export default new Vuex.Store({
   state: initialState(),
   mutations: {
      SET_NOTIFICATION(state, payload) {
         state.notification = payload;
      },
      setError(state, payload) {
         state.error = payload;
      },
   },
   actions: {
      setNotification({ commit }, { message, type }) {
         const toastSettings = {
            error: {
               duration: 4000,
               type: "is-danger",
            },
            success: {
               duration: 5000,
               type: "is-success",
            },
         };

         Toast.open({
            ...toastSettings[type],
            message: message,
         });
         // Pause on hover
      },
   },
   getters: {
      error(state) {
         return state.error;
      },
   },

   modules: {
      portfolio: portfolio,
      user: user,
      market: market,
   },
});
