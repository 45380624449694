<template>
	<basic-layout>
		<div class="register-page">
			<h1>Register</h1>
			<!-- <p>Fill in your email and password to proceed</p> -->
			<CreateAccount />
		</div>
	</basic-layout>
</template>

<script>
import CreateAccount from "@/components/CreateAccount.vue";
import BasicLayout from "./layouts/Basic";

export default {
	components: {
		CreateAccount,
		BasicLayout,
	},
};
</script>

<style lang="sass" scoped>
.register-page
   margin-top: -15%
   max-width: 600px
</style>