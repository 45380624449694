<template>
	<div class="app">
		<div class="above-the-fold">
			<Navbar />
			<main class="main">
				<router-view></router-view>
			</main>
		</div>
		<hr />
		<Footer class="has-background-dark" />
	</div>
</template>

<script>
import { mapActions } from "vuex";
import Navbar from "./components/TheNavbar";
import Footer from "./components/TheFooter";

export default {
	components: {
		Navbar,
		Footer,
	},
	methods: {
		...mapActions("user", ["refreshAuth"]),
		setTheme() {
			let root = document.documentElement;
			root.className = "lightmode";
			// root.style.setProperty("--color-b", "#FFFFFF");
		},
	},
	mounted() {
		this.refreshAuth();
		this.$store.dispatch("portfolio/fetchPortfolio");
		this.$store.dispatch("market/fetchMarketData");
	},
};
</script>

<style src="buefy/dist/buefy.css"></style>

<style lang="sass" scoped>
.app
   // min-height: 100vh

.main
   // min-height: 60vh

.above-the-fold
   min-height: 100vh

::v-deep
   .toast.is-danger *
      color: white !important
</style>