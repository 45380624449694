<template>
	<Layout>
		<div>Updates</div>
		<div>
			<h3 class="date">{{ date }}</h3>
			<h1 class="title">{{ title }}</h1>
			<markdown class="markdown" />
		</div>
	</Layout>
</template>

<script>
import Layout from "../layouts/Content";
import markdown from "@/../content/updates/2021-12-23-v0.0.1/index.md";

export default {
	data() {
		return {
			title: markdown.attributes.title,
			date: markdown.attributes.date,
		};
	},
	components: {
		Layout,
		markdown: markdown.vue.component,
	},
};
</script>

<style lang="sass" scoped>
::v-deep
   .markdown *, .title, .date
      color: var(--color-t)

   .date
      margin: 0
</style>