/* Content Routes */
import Philosophy from "@/pages/content/Philosophy";
import Strategy from "@/pages/content/Strategy";
import Updates from "@/pages/content/Updates";
import WithdrawDeposit from "@/pages/content/WithdrawDeposit";
import Fees from "@/pages/content/Fees";
import Resources from "@/pages/content/Resources";

const contentRoutes = [
   {
      path: "/philosophy",
      name: "Philosophy",
      component: Philosophy,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/strategy",
      name: "Strategy",
      component: Strategy,
   },
   {
      path: "/updates",
      name: "Updates",
      component: Updates,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/withdraw-deposit",
      name: "Withdraw & Deposit",
      component: WithdrawDeposit,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/fees",
      name: "Fees",
      component: Fees,
      meta: {
         authRequired: true,
      },
   },
   {
      path: "/resources",
      name: "Resources",
      component: Resources,
      meta: {
         authRequired: true,
      },
   },
];

export default contentRoutes;
