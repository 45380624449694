<template>
	<div class="basic-layout">
		<div class="content">
			<slot></slot>
			<div v-if="this.$env === 'development'">
				<h6 class="github-edit-link">
					<a
						href="https://github.com/foundations-design/EmTF/tree/main/site/content"
						target="_blank"
					>
						Edit on GitHub
					</a>
				</h6>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
@import "@/styles/e_layout/_media.sass"

.basic-layout
   padding-top: var(--space-lg)

   .content
      display: grid
      place-contents: center
      max-width: 600px
      margin: auto

.github-edit-link
   text-align: right

   a
      color: var(--color-a)
      font-size: var(--d-sm)
</style>