<template>
	<div class="overview-page">
		<section class="section container">
			<h2>
				<a href="/assets"> Asset Summaries </a>
			</h2>
			<div class="widgets" v-if="getLegacyUserAccount">
				<NumberBox
					class="widget ab"
					title="General Fund Balance"
					:value="formatPrice(getTotalAccountBalance)"
				/>
				<NumberBox
					class="widget gr"
					title="Growth"
					:value="formatPercent(getAccountGrowth)"
				/>
				<NumberBox
					class="widget fe"
					title="Fund Equity"
					:value="formatPercent(getTotalEquity)"
				/>
				<NumberBox
					v-if="getManagedWalletBalance"
					class="widget mw"
					title="Managed Wallet"
					:value="formatPrice(getManagedWalletBalance)"
				/>
				<div class="widget table">
					<h2>Holdings List<br />coming soon</h2>
				</div>
				<!-- <GeneralTable
					class="widget table"
					:data="holdingsMini"
					title="Holdings"
					fbRef="portfolio/holdings"
				/> -->
			</div>
		</section>
		<section class="section container" v-if="this.$env === 'development'">
			<h2>Latest Update:</h2>
			<div class="update">
				<DynamicMarkdownComponent
					fileName="updates/2021-12-23-v0.0.1/index.md"
				/>
			</div>
		</section>
	</div>
</template>

<script>
/*eslint-disable*/
import NumberBox from "@/components/NumberBox.vue";
import GeneralTable from "@/components/GeneralTable.vue";
import DynamicMarkdownComponent from "@/components/DynamicMarkdownComponent.vue";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			numberboxData: {
				ab: { title: "Account Balance", value: "$1083.38" },
				pi: { title: "Physical Inventory", value: "$100.01" },
				di: { title: "Digital Inventory", value: "$200.01" },
			},
		};
	},
	computed: {
		...mapGetters("portfolio", ["getHoldings"]),
		...mapGetters("user", [
			"getLegacyUserAccount",
			"getTotalAccountBalance",
			"getManagedWalletBalance",
			"getAccountGrowth",
			"getTotalEquity",
		]),
		holdingsMini() {
			let holdings = this.getHoldings;
			Object.keys(holdings).map((h) => {
				holdings[h] = {
					Ticker: holdings[h].Ticker,
					Quantity: holdings[h].Quantity,
				};
			});

			return holdings;
		},
	},
	components: {
		NumberBox,
		GeneralTable,
		DynamicMarkdownComponent,
	},
};
</script>

<style lang="sass" scoped>
h2
   margin-bottom: var(--space-sm)

.box
   opacity: .5

.widgets
   display: grid
   grid-template-columns: repeat(4, auto)
   grid-template-rows: 1fr 1fr 1fr
   grid-gap: var(--space-xs)
   margin-bottom: var(--space-md)

   .widget
      padding: var(--space-xs)
      background-color: var(--color-b-alt)

   .ab
      grid-row: 1
      grid-column: 1/3

   .mw
      grid-column: 1/3

   .table
      grid-column: 3/5
      grid-row: 1/-1

.update
   background-color: var(--color-b-alt)
   padding: var(--space-sm)
</style>