<template>
	<div>
		<footer class="footer">
			<div class="container" v-if="getRole === 'General Partner'">
				<AllRoutes />
			</div>
			<div class="container">
				<div class="project-files">
					<h3 class="">Project Files:</h3>
					<ul>
						<li v-for="(file, i) in projectFiles" :key="i">
							<a :href="file.href" target="_blank">
								{{ file.name }}
							</a>
						</li>
					</ul>
				</div>
				<ThemeToggle class="theme-toggle" />
			</div>
			<div class="has-text-centered">
				<p>
					<strong class="">eMTF</strong> by
					<a href="https://foundations.design/mission"
						>Foundations Design</a
					>. All rights reserved.
				</p>
				<p class="small">
					<router-link to="/updates"> v0.0.1 </router-link>
				</p>
			</div>
		</footer>
	</div>
</template>

<script>
import AllRoutes from "@/components/utility/AllRoutes";
import ThemeToggle from "@/components/ThemeToggle";
import { mapGetters, mapActions } from "vuex";

export default {
	data() {
		return {
			projectFiles: [
				{
					name: "Bug Submission Form",
					href: "https://github.com/foundations-design/emtf-issue-tracker/issues/new?assignees=earlman&labels=bug&template=BUG-REPORT.yml&title=%5Bemtf-issue-tracker%5D%3A+",
				},
				{
					name: "Google Sheets - eMTF Digital Inventory v3",
					href: "https://docs.google.com/spreadsheets/d/1vxV6NhvUiE45_B_N0DhYPGhQdBggYw-Y0eDaRaJewxA/edit?usp=sharing",
				},
				{
					name: "Discord",
					href: "https://discord.gg/g9acwG5kSa",
				},
			],
		};
	},
	components: {
		AllRoutes,
		ThemeToggle,
	},
	computed: {
		...mapGetters("user", ["getRole"]),
	},
};
</script>

<style lang="sass" scoped>
.footer
   margin-top: var(--space-xl)
   background-color: var(--color-b-alt)

   .project-files, .theme-toggle
      margin-bottom: var(--space-sm)

.container
   display: flex
   justify-content: space-between

.small
   font-size: var(--d-sm)
</style>