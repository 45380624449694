<template>
	<div class="add-location" @keyup.enter="submit">
		<h2>Add Location</h2>
		<b-field label="Display Name">
			<b-input v-model="formData.DisplayName"></b-input>
		</b-field>
		<b-field label="URL">
			<b-input v-model="formData.Url"></b-input>
		</b-field>
		<b-button @click="submit" class="is-primary" icon-left="plus"
			>Add Location</b-button
		>
	</div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

import { mapActions } from "vuex";

export default {
	data() {
		return {
			formData: {
				DisplayName: "",
				Url: "",
			},
		};
	},
	computed: {},

	methods: {
		...mapActions("portfolio", ["addLocation"]),

		submit() {
			this.addLocation(this.formData).then(() => {
				// clear form
				this.formData = {
					DisplayName: "",
					Url: "",
				};
			});
		},
	},
};
</script>

<style lang="sass" scoped>
.add-location
   background-color: var(--color-b-alt)
   padding: var(--space-xs)

   ::v-deep
      label
         font-weight: 400
         color: var(--color-t-c)
      .disabled
         color: var(--color-b-alt-2)
      .dropdown-item *
         color: var(--color-b)
</style>