<template>
	<div class="holdings-view">
		<div class="view-options">
			<b-field>
				<b-radio-button
					v-model="view"
					native-value="table"
					type="is-primary is-light is-outlined"
				>
					<b-icon icon="bars"></b-icon>
					<span>Table</span>
				</b-radio-button>
				<b-radio-button
					v-model="view"
					native-value="cards"
					type="is-primary is-light is-outlined"
				>
					<b-icon icon="th"></b-icon>
					<span>Cards</span>
				</b-radio-button>
			</b-field>
		</div>
		<div class="table-container" v-if="view === 'table'">
			<GeneralTable
				:data="getHoldings"
				title="Holdings"
				fbRef="portfolio/holdings"
				:showDelete="getRole === 'General Partner'"
			/>
		</div>
		<div class="cards-container" v-if="view === 'cards'">
			<HoldingsCards />
		</div>
	</div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable.vue";
import HoldingsCards from "./HoldingsCards";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			view: "cards",
		};
	},
	computed: {
		...mapGetters("portfolio", ["getHoldings"]),
		...mapGetters("user", ["getRole"]),
	},
	components: {
		GeneralTable,
		HoldingsCards,
	},
};
</script>

<style lang="sass" scoped>
.view-options
   width: 100%
   margin-bottom: var(--space-md)
</style>
